<template>
  <section class="login">
    <div
      class="background"
      :style="{ 'background-color': themeConfig.bg_color }"
    ></div>
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div
          class="
            content-wrapper
            d-flex
            align-items-center
            justify-content-center
            auth
            theme-one
          "
        >
          <div class="row w-100">
            <div class="col-lg-4 mx-auto">
              <div class="auto-form-wrapper">
                <b-overlay :show="showLoading">
                  <div v-if="themeConfig.logo_url" class="text-center mb-3">
                    <img class="logo-img" :src="themeConfig.logo_url" />
                  </div>
                  <div class="text-center mb-3">
                    <h4>{{ organizationName }} 管理後台</h4>
                  </div>
                  <form @submit.prevent>
                    <validation-error-alert
                      v-if="validationErrors"
                      :errors="validationErrors"
                    ></validation-error-alert>
                    <!-- <div class="form-group">
											<label class="label">登入方式</label>
											<select class="form-control" v-model="authenticateMethod">
												<option value="email">E-Mail</option>
												<option value="employee_code">員工編號</option>
											</select>
										</div> -->
                    <div class="form-group">
                      <label class="label">{{ authFieldName }}</label>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          :placeholder="'請輸入 ' + authFieldName"
                          v-model="form.auth_field"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text">
                            <i class="mdi mdi-check-circle-outline"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="label">密碼</label>
                      <div class="input-group">
                        <input
                          type="password"
                          class="form-control"
                          placeholder="請輸入密碼"
                          v-model="form.password"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text">
                            <i class="mdi mdi-check-circle-outline"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <button
                        class="btn btn-primary submit-btn btn-block"
                        @click="submit"
                      >
                        登入
                      </button>
                    </div>
                  </form>
                </b-overlay>
              </div>
              <p class="footer-text text-center">
                copyright © 2022 Waltily. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      showLoading: false,
      validationErrors: null,
      authenticateMethod: "employee_code",
      form: {
        auth_field: null,
        password: null,
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      organizationThemeConfig: (state) => state.organizationThemeConfig,
    }),
    authFieldName() {
      return this.authenticateMethod === "email" ? "E-Mail" : "帳號";
    },
    organizationName() {
      return this.organization ? this.organization.name : "Waltily";
    },
    themeConfig() {
      if (this.organizationThemeConfig.auth) {
        return this.organizationThemeConfig.auth;
      } else {
        return {
          bg_color: "#4c4c4c",
          logo_url: null,
          og_title: null,
          og_favicon_url: null,
        };
      }
    },
  },
  mounted() {
    if (
      this.themeConfig.og_title !== null ||
      this.themeConfig.og_title !== undefined
    ) {
      document.title = this.themeConfig.og_title;
    } else {
      document.title = "";
    }

    if (
      this.themeConfig.og_favicon_url !== null ||
      this.themeConfig.og_favicon_url !== undefined
    ) {
      var link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = this.themeConfig.og_favicon_url;
    }
  },
  methods: {
    async submit() {
      this.showLoading = true;
      const params = {
        auth_method: this.authenticateMethod,
        password: this.form.password,
        org_id: this.organization.id,
      };

      if (this.authenticateMethod === "email") {
        params.email = this.form.auth_field;
      }
      if (this.authenticateMethod === "employee_code") {
        params.employee_code = this.form.auth_field;
      }

      await this.$store
        .dispatch("auth/login", params)
        .then(() => {
          this.$router.push({
            name: "DashboardJump",
            params: { org_code: this.$route.params.org_code },
          });
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status) {
            switch (error.response.status) {
              case 422:
                this.validationErrors = error.response.data.message;
                break;
              case 401:
                this.$swal(
                  "失敗",
                  this.authFieldName + "或密碼錯誤，請重新登入",
                  "error"
                );
                break;
              default:
                this.$swal("失敗", error.response.data.message, "error");
                break;
            }
          }
          this.showLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.background {
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.logo-img {
  width: 150px;
}
</style>
